import React, { useState } from "react";
import "./index.scss";
import SubNavbar from "../../components/SubNavbar";
import { CreditCardOutlined } from "@material-ui/icons";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import { paymentService } from "../../services/paymentServices";
import { v4 as uuidv4 } from "uuid";
import { useSelector } from "react-redux";
import { fetchWrapper } from "../../helpers/fetchWrapper";
import { useNavigate } from "react-router";
import { Socket } from "socket.io-client";
import io from "socket.io-client";
import axios from "axios";
import {
  DatePicker,
  DateTimePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import { Input } from "@material-ui/core";
import { ToastContainer, toast } from "react-toastify";

function DepositPage() {
  const [isUsePaymentGate1Clicked, setisUsePaymentGate1Clicked] =
    useState(false);
  const [isUsePaymentGate2Clicked, setisUsePaymentGate2Clicked] =
    useState(false);
  const [postCode, setPostCode] = useState("");
  const [streetAddress, setStreetAddress] = useState("");
  const [streetAddressNumber, setStreetAddressNumber] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [depositValue, setDepositValue] = useState(null);
  const [dateOfBirth, setDateOfBirth] = React.useState(dayjs(new Date()));
  const [dateOfBirthPicked, setDateOfBirthPicked] = React.useState(false);

  const uniqueId = uuidv4();
  const [redirectUrl, setRedirectUrl] = useState(null);
  const navigate = useNavigate();
  const mobileWidth = window.innerWidth;
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };
  const CryptoJS = require("crypto-js");
  const addCreditCardHandler = () => {
    setisUsePaymentGate1Clicked((prev) => !prev);
  };
  const usePaymentGate2Handler = () => {
    setisUsePaymentGate2Clicked((prev) => !prev);
  };
  const user = useSelector(
    (state) => state.user && state.user.user && state.user.user.foundUser
  );
  const handleValueChange = (e) => {
    const result = e.target.value.replace(/\D/g, "");
    setDepositValue(result);
  };

  const handleChange = (e) => {
    e.preventDefault();
    switch (e.target.name) {
      case "country":
        setCountry(e.target.value);
        return;
      case "city":
        setCity(e.target.value);
        return;
      case "streetAddress":
        setStreetAddress(e.target.value);
        return;
      case "streetAddressNumber":
        setStreetAddressNumber(e.target.value);
        return;
      case "postCode":
        setPostCode(e.target.value);
        return;
      case "":
        setDepositValue(e.target.value.replace(/\D/g, ""));
        return;
      default:
        break;
    }
  };

  // const handleCreatePayment = () => {
  //   // socket.emit("deposit", {
  //   //   email: user.email,
  //   // });
  //   paymentService
  //     .createPayment({
  //       amount: depositValue,
  //       userEmail: user.email,
  //       userFirstName: user.firstName,
  //       userLastName: user.lastName,
  //       userCity: user.city,
  //       userAddress: user.streetAddress,
  //       userPostCode: user.postCode,
  //       userPhone: user.phone,
  //     })
  //     .then((res) => {
  //       // navigate('/payment-page')
  //       window.location.replace(`${res.redirect_url}`);
  //       // setRedirectUrl(res.redirect_url)
  //     });
  // };
  const handleCreatePayment = async () => {
    // await axios.post(
    //   "https://sandbox-payapi.xcoins.network/v1/merchant/transactions/intiate-deposit",
    //   {
    //     merchantRef: "ref",
    //     userRef: "userRef",
    //     transactionRef: "asd",
    //     fullName: user.firstName + " " + user.lastName,
    //     firstName: user.firstName,
    //     lastName: user.lastName,
    //     currencyCode: "EUR",
    //     amount: depositValue,
    //     country: "Sweden",
    //     dateOfBirth: "1995-03-24T00:00:00.000Z",
    //     // phoneCode: user.phone[0] + '' +user.phone[1],
    //     // phoneNumber: user.phone,
    //     // state: 'Stockholm',
    //     // city: 'Stockholm',
    //     // address: "Addreess",
    //     // addressTwo: 'Address2',
    //     // addressNumber: 1,
    //     // email: user.email,
    //     // zipCode: '2331'
    //   },
    //   {
    //     headers: {
    //       Authorization: `Bearer API_KEY`,
    //     },
    //   }
    // );
  };
  const handleCreatePaymentAlternative2 = () => {
    // socket.emit("deposit", {
    //   email: user.email,
    // });
    paymentService
      .createPaymentAlternative2({
        amount: depositValue,
        userEmail: user.email,
        userFirstName: user.firstName,
        userLastName: user.lastName,
        userCity: user.city,
        userAddress: user.streetAddress,
        userPostCode: user.postCode,
        userPhone: user.phone,
      })
      .then((res) => {
        // navigate('/payment-page')
        window.location.replace(`${res.redirect_url}`);
        // setRedirectUrl(res.redirect_url)
      });
  };
  const handleRedirectToTheThirdProcessor = () => {
    window.location.replace(
      "https://educationlessons.co.uk/payments/?user=126"
    );
  };
  // const handleCreatePayment = async (e) => {
  //   e.preventDefault();
  //   const orderId = uuidv4();
  //   const orderCurrency = "eur";

  //   const merchantPass = '0ea8f36811440312534756faef9c84a5';
  //   const merchantKey = '057058bc-0405-11ed-aaa6-26f7bb7ce154';
  //   const checkoutUrl = 'https://checkout.rafinita.com/api/v1/session';
  //   const orderDescription = 'Deposit Money';

  //   const to_md5 = orderId + Number(depositValue).toFixed(2) + orderCurrency + orderDescription + merchantPass;
  //   const hash = CryptoJS.SHA1(CryptoJS.MD5(to_md5.toUpperCase()).toString());
  //   const sessionHash = CryptoJS.enc.Hex.stringify(hash);

  //   const body = {
  //     merchant_key: merchantKey,
  //     operation: 'purchase',
  //     methods: ["card"],
  //     order: {
  //       number: orderId,
  //       amount: Number(depositValue).toFixed(2),
  //       currency: 'EUR',
  //       description: orderDescription,
  //     },
  //     cancel_url: 'https://aquila-markets.com/canceled-deposit',
  //     success_url: 'https://aquila-markets.com/successful-deposit',
  //     customer: {
  //       name: `${user.firstName} ${user.lastName}`,
  //       email: user.email
  //     },
  //     billing_address: {
  //       city: user.city,
  //       address: user.streetAddress,
  //       zip: user.postCode,
  //       phone: user.phone,
  //     },
  //     recurring_init: 'true',
  //     hash: sessionHash
  //   }

  //   try {
  //     const makeDeposit = await fetch(`${checkoutUrl}`, {
  //       method: 'POST',
  //       headers: {"Content-Type": "application/json"},
  //       body: JSON.stringify(body),
  //     });
  //     return await makeDeposit.json()
  //   } catch(e) {
  //     throw new Error(e)
  //   }
  // }

  const handleCreateInstantPayment = async () => {
    await paymentService
      .instantPay({
        userRef: user?.id?.toString(),
        transactionRef: uniqueId,
        fullName: user?.firstName + " " + user?.lastName,
        firstName: user?.firstName,
        lastName: user?.lastName,
        amount: +Number(depositValue).toFixed(2),
        country: country,
        dateOfBirth: new Date(dateOfBirth)?.toISOString(),
        onrEnabled: true,
        phoneCode:
          user.phone[0] + "" + user.phone[1] + country.toLowerCase() ===
          "cyprus"
            ? user.phone[2]
            : "",
        phoneNumber: "+" + user?.phone,
        city: city,
        streetAddress,
        streetAddressNumber,
        email: user?.email,
        postCode,
      })
      .then((res) => {
        const refId = res.data.id;

        window.location.replace(`https://instantpay.xcoins.com?ref=${refId}`);
      })
      .catch((error) => {
        toast.error(`Something went wrong: ${error}`, {
          position: "top-center",
          hideProgressBar: false,
          closeOnClick: true,
          autoClose: false,
          pauseOnHover: true,
          toastId: 3,
        });
        console.log("Error fetching the html", error);
      });
  };

  return (
    <div className="deposit-page-root-container-parent-root">
      <SubNavbar></SubNavbar>
      <div className="deposit-page-root-container-parent">
        <div className="deposit-page-root-container">
          <div className="deposit-page-container-header">
            <h3>DEPOSIT</h3>
          </div>
          <div className="deposit-page-payment-method-header">
            <h5>Choose Payment Method</h5>
          </div>
          <div className="deposit-page-payment-gate-container">
            {/* <div
            className={
              !isUsePaymentGate1Clicked
                ? `deposit-page-credit-card-container`
                : `deposit-page-credit-card-container-active`
            }
            onClick={addCreditCardHandler}
          >
            <CreditCardOutlined />
            <h5>Payment Gate 1</h5>
          </div> */}
            <div
              className={
                !isUsePaymentGate2Clicked
                  ? `deposit-page-credit-card-container`
                  : `deposit-page-credit-card-container-active`
              }
              onClick={usePaymentGate2Handler}
            >
              {/* <CreditCardOutlined /> */}
              {/* <h5>Payment Gate 1</h5> */}
            </div>
          </div>
        </div>
      </div>
      {/* {isUsePaymentGate1Clicked && (
        <div className="deposit-page-root-container-parent-active" >
          {console.log('inside')}
          <div className="deposit-page-root-container-active">
            <>
            <div className="deposit-page-container-header-active">
              <h3>DEPOSIT USING CREDIT/DEBIT CARDS</h3>
            </div>
            <div className="deposit-page-payment-method-header-active">
              <h5>Amount</h5>
            </div>
            </>
            <form className="depositForm">
            <InputLabel htmlFor="standard-adornment-amount" style={{color: '#d12424', padding: '5px' }}>
                Minimum of €250.00
              </InputLabel>
              <input onChange={handleValueChange} type='text' name='depositValue' placeholder="Deposit Money" className="depositInput"></input>
              <button type="button" disabled={depositValue < 250} className="depositButton" onClick={() => handleCreatePayment()}>Deposit</button>
            </form> 
          </div>
        </div>
      )} */}
      {isUsePaymentGate2Clicked && (
        <div className="deposit-page-root-container-parent-active">
          <div className="deposit-page-root-container-active">
            <>
              <div className="deposit-page-container-header-active">
                <h3>DEPOSIT USING CREDIT/DEBIT CARDS</h3>
              </div>
            </>
            {/* <form className="depositForm">
              <InputLabel
                htmlFor="standard-adornment-amount"
                style={{ color: "#d12424", padding: "5px" }}
              >
                Minimum of {user?.currencySymbol}199.00
              </InputLabel>
              <input
                onChange={handleValueChange}
                type="text"
                name="depositValue"
                placeholder="Deposit Money"
                className="depositInput"
              ></input>
              {/* <button
                type="button"
                disabled={depositValue < 250}
                className="depositButton"
                onClick={() =>
                  openInNewTab(
                    "https://educationlessons.co.uk/payments/?user=126"
                  )
                }
              >
                Deposit
              </button> */}
            {/* <button
              type="button"
              disabled={depositValue < 199}
              className="depositButton"
              onClick={() => handleCreateInstantPayment()}
            >
              Deposit
            </button> */}
            {/* </form> */}
            <div className="wallet-page-information-wrapper">
              <FormControl
                focused={true}
                fullWidth
                sx={{ m: 1 }}
                variant="standard"
              >
                <InputLabel htmlFor="country">Country</InputLabel>
                <Input
                  inputProps={{
                    style: {
                      backgroundColor: "#07232E",
                    },
                  }}
                  className="input-root"
                  name="country"
                  // placeholder="Country"
                  onChange={(e) => handleChange(e)}
                  value={country}
                />
              </FormControl>
              <FormControl
                focused={true}
                fullWidth
                sx={{ m: 1 }}
                variant="standard"
              >
                <InputLabel htmlFor="city">City</InputLabel>
                <Input
                  inputProps={{
                    style: {
                      backgroundColor: "#07232E",
                    },
                  }}
                  className="input-root"
                  name="city"
                  // placeholder="City"
                  onChange={(e) => handleChange(e)}
                  value={city}
                />
              </FormControl>
              <FormControl
                focused={true}
                fullWidth
                sx={{ m: 1 }}
                variant="standard"
              >
                <InputLabel htmlFor="streetAddress">Street address</InputLabel>
                <Input
                  inputProps={{
                    style: {
                      backgroundColor: "#07232E",
                    },
                  }}
                  className="input-root"
                  name="streetAddress"
                  // placeholder="Street address"
                  onChange={(e) => handleChange(e)}
                  value={streetAddress}
                />
              </FormControl>
              <FormControl
                focused={true}
                fullWidth
                sx={{ m: 1 }}
                variant="standard"
              >
                <InputLabel htmlFor="streetAddressNumber">
                  Street address number
                </InputLabel>
                <Input
                  inputProps={{
                    style: {
                      backgroundColor: "#07232E",
                    },
                  }}
                  className="input-root"
                  name="streetAddressNumber"
                  // placeholder="Street address number"
                  onChange={(e) => handleChange(e)}
                  value={streetAddressNumber}
                />
              </FormControl>

              <FormControl
                focused={true}
                fullWidth
                sx={{ m: 1 }}
                variant="standard"
              >
                <InputLabel htmlFor="postCode">Post Code/Zip</InputLabel>
                <Input
                  inputProps={{
                    style: {
                      backgroundColor: "#07232E",
                    },
                  }}
                  className="input-root"
                  name="postCode"
                  // placeholder="Post Code/Zip"
                  onChange={(e) => handleChange(e)}
                  value={postCode}
                />
              </FormControl>
              <FormControl
                focused={true}
                fullWidth
                sx={{ m: 1 }}
                variant="standard"
              >
                <InputLabel htmlFor="amount">
                  {" "}
                  Minimum of {user?.currencySymbol}199.00
                </InputLabel>
                <Input
                  inputProps={{
                    style: {
                      backgroundColor: "#07232E",
                    },
                  }}
                  className="input-root"
                  name=""
                  // placeholder="Amount"
                  onChange={(e) => handleChange(e)}
                  value={depositValue}
                />
              </FormControl>
              {/* <DateTimePickerComponent
                date={dateOfBirth}
                depositView={true}
                setDate={setDateOfBirth}
                label={"Date of birth"}
              /> */}
              <FormControl
                focused={true}
                fullWidth
                sx={{ m: 1 }}
                variant="standard"
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <InputLabel sx={{}} htmlFor="dateOfBirth">
                    Date of birth
                  </InputLabel>
                  <DateTimePicker
                    views={[
                      "year",
                      "month",
                      "day",
                      // "hours",
                      // "minutes",
                      // "seconds",
                    ]}
                    sx={{
                      width: "100%",
                    }}
                    disableFuture
                    className="date-picker-styles"
                    // viewRenderers={{
                    //   hours: renderTimeViewClock,
                    //   minutes: renderTimeViewClock,
                    //   seconds: renderTimeViewClock,
                    // }}

                    closeOnSelect={true}
                    // label={"Date of birth"}
                    // value={dateOfBirth}
                    onChange={(newDate) => {
                      setDateOfBirth(new Date(newDate).getTime());
                      setDateOfBirthPicked(true);
                    }}
                  />
                </LocalizationProvider>
              </FormControl>

              <Button
                variant="contained"
                color="success"
                //
                style={{ width: "30%", margin: "0 auto" }}
                className="wallet-page-payment-method-button"
                // onClick={(e) => handleUpdateUser(e)}
                disabled={
                  depositValue < 199 &&
                  !country &&
                  !city &&
                  !streetAddress &&
                  !streetAddressNumber &&
                  !postCode &&
                  !dateOfBirthPicked
                }
                // className="depositButton"
                onClick={() => handleCreateInstantPayment()}
              >
                Deposit
              </Button>
            </div>
          </div>
        </div>
      )}
      <ToastContainer />
    </div>
  );
}

export default DepositPage;
